import { useState } from 'react';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Flex, FormErrorMessage, Link } from '@chakra-ui/react';
import { Button, FormControl, FormLabel, Input, Spacer, Text } from '@scope';
import cookies from 'js-cookie';

import userApi from 'api/user';
import { isDev } from 'config/env';
import { regexps } from 'utils/utilities';

type FormData = {
  email: string;
  password: string;
};

function UserLoginForm() {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [authError, setAuthError] = useState<any>(null);
  const router = useRouter();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (userData: FormData) => {
    setIsLoggingIn(true);

    try {
      const token = await userApi.login(userData);
      cookies.set('at', token.accessToken, {
        path: '/',
        expires: 30, // 30 days
        // secure: !isDev,
        sameSite: 'none',
        secure: true,
      });
      cookies.set('rt', token.refreshToken, {
        path: '/',
        expires: 30, // 30 days
        // secure: !isDev,
        sameSite: 'none',
        secure: true,
      });
      const nextUrl = router.query?.next?.toString();
      router.push(nextUrl || '/');
    } catch (error) {
      setAuthError(error.message);
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="431px"
      p="8"
      mx="auto"
      mt="8"
    >
      <Flex direction="column" align="center" textAlign="center">
        <Image src="/logo-black.svg" alt="Scope Logo" height={36} width={116} />

        <Spacer h={8} />

        <Text as="h1" ms={2} bold>
          Welcome to Scope
        </Text>

        <Text as="p" color="ui.gray.dark">
          The all-in-one expert marketplace
        </Text>
      </Flex>

      {authError ? (
        <>
          <Spacer h={4} />

          <Text color="ui.red">{authError}</Text>

          <Spacer h={4} />
        </>
      ) : (
        <Spacer h={8} />
      )}

      <FormControl id="email" isInvalid={!!errors.email}>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          name="email"
          placeholder="Enter email"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: regexps.email,
              message: "This doesn't look like a real email",
            },
          })}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <Spacer h={4} />

      <FormControl id="password" isInvalid={!!errors.password}>
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          name="password"
          placeholder="Enter password"
          {...register('password', {
            required: 'Password is required',
          })}
        />
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>

      <Spacer h={10} />

      <Flex direction="column">
        <Button type="submit" colorScheme="primary" isLoading={isLoggingIn}>
          Log In
        </Button>

        <Spacer h={2} />

        <NextLink href="/reset" passHref>
          <Link
            color="ui.blue.primary"
            fontWeight="bold"
            display="flex"
            justifyContent="flex-end"
          >
            Forgot password?
          </Link>
        </NextLink>
      </Flex>

      <Spacer h={8} />

      <Text
        as="p"
        color="ui.gray.dark"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        Don't have an account?
        <NextLink href="/signup" passHref>
          <Link color="ui.blue.primary" fontWeight="bold" pl="0.5ch">
            Sign up
          </Link>
        </NextLink>
      </Text>
    </Box>
  );
}

export default UserLoginForm;
