import { useEffect, useRef } from 'react';

const PERIOD = {
  DAY: 'day(s)',
  WEEK: 'week(s)',
  MONTH: 'month(s)',
  HOUR: 'hour(s)',
};

const DAY_HOURS = 24;
const WEEK_HOURS = 168;
const MONTH_HOURS = 730;

/* eslint-disable import/prefer-default-export */
export const regexps = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  USPhoneNumber: /((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/,
  website:
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  digits: /^\d+$/,
};

// Helper functions for filtering deliverables by their status.
export const byToDoDeliverables = (deliverable) =>
  deliverable.status === 'TO_DO';
export const byInProgressDeliverables = (deliverable) =>
  deliverable.status === 'IN_PROGRESS';
export const byDoneDeliverables = (deliverable) =>
  deliverable.status === 'DONE';

export const isPositionChanged = (source, destination) => {
  if (!destination) return false;
  const isSameList = destination.droppableId === source.droppableId;
  const isSamePosition = destination.index === source.index;
  return !isSameList || !isSamePosition;
};

// sorting helper function (usage: deliverables.sort(byOrderIndex))
export const byOrderIndex = (a, b) => {
  return parseInt(a.orderIndex, 10) - parseInt(b.orderIndex, 10);
};

export function usePrevious<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

// prepends
export const prependProtocolToUrl = (url = '') => {
  const HTTPS = 'HTTPS://';
  const HTTP = 'HTTP://';

  if (
    url.toUpperCase().startsWith(HTTPS) ||
    url.toUpperCase().startsWith(HTTP)
  ) {
    return url;
  }

  return `https://${url}`;
};

export const removeDuplicates = (array: Record<string, unknown>[]) => {
  return Array.from(new Set(array));
};

export const removeDuplicatesById = (array: Record<string, unknown>[]) => {
  return Array.from(new Set(array.map((a) => a.id))).map((id) => {
    return array.find((a) => a.id === id);
  });
};

export const getUserFullName = ({ firstName, lastName }) => {
  if (!firstName || !lastName) return null;
  return `${firstName} ${lastName}`;
};

export const capitalize = (word: string) => {
  return word[0] + word.slice(1).toLowerCase();
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getHoursFromTime = ({
  time,
  period,
}: {
  time: number;
  period: string;
}) => {
  switch (period) {
    case PERIOD.DAY: {
      return time * DAY_HOURS;
    }
    case PERIOD.WEEK: {
      return time * WEEK_HOURS;
    }
    case PERIOD.MONTH: {
      return time * MONTH_HOURS;
    }
    case PERIOD.HOUR: {
      return time * 1;
    }
    default: {
      return '';
    }
  }
};

export const getTimeFromTimeframe = (timeframe: string) => {
  const [time, period] = timeframe.split(' ');

  return { time, period };
};

export const getTimeFromHours = (hours: number) => {
  if (hours < WEEK_HOURS) {
    return {
      time: hours / DAY_HOURS,
      period: 'day(s)',
    };
  }

  if (hours < MONTH_HOURS) {
    return {
      time: hours / WEEK_HOURS,
      period: 'week(s)',
    };
  }

  return {
    time: hours / MONTH_HOURS,
    period: 'month(s)',
  };
};

export const splitName = (fullName: string) => {
  const nameArray = fullName.split(' ');
  const firstName = nameArray.shift();
  const lastName = nameArray.join(' ');
  return { firstName, lastName };
};

export const copyToClipboard = async (text) => {
  if (!navigator?.clipboard) {
    console.warn('Clipboard not supported');
    return false;
  }

  // Try to save to clipboard then save it in the state if worked
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    console.warn('Copy failed', error);
    return false;
  }
};
