import { getLayout } from 'components/Layout';
import PageContent from 'components/PageContent';
import UserLoginForm from 'components/UserLoginForm';

function Login() {
  return (
    <PageContent>
      <UserLoginForm />
    </PageContent>
  );
}

Login.getLayout = (page) =>
  getLayout(page, 'Login', {
    hideHeader: true,
    redirectWithCookie: false,
    useUserProps: { redirectIfFound: '/' },
  });

export default Login;
